import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/components/layout/index.jsx";
import links from '@utils/externalLinks';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const GridList = makeShortcode("GridList");
const GridListItem = makeShortcode("GridListItem");
const Section = makeShortcode("Section");
const Button = makeShortcode("Button");
const ButtonGroupWrapper = makeShortcode("ButtonGroupWrapper");
const ButtonGroupSecondary = makeShortcode("ButtonGroupSecondary");
const GetInTouch = makeShortcode("GetInTouch");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "lunas-design-foundations",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#lunas-design-foundations",
        "aria-label": "lunas design foundations permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Luna's design foundations`}</h2>
    <p>{`Luna is a multi-brand design system. We want each brand to remain unique, but also to share things where consistent standards and approaches will create better experiences for users. This idea applies to everything that we do.`}</p>
    <GridList columns="2" mdxType="GridList">
  <GridListItem title="Colour" titleElement="h3" linkText="Learn more about colour" linkTo="/foundations/colour" border icon="colour" mdxType="GridListItem">
    Brands have individual colours, but share a monochrome and semantic palette.
  </GridListItem>
  <GridListItem title="Typography" titleElement="h3" linkText="Learn more about typography" linkTo="/foundations/typography" border icon="typography" mdxType="GridListItem">
    The typography system provides a flexible approach that can be applied to
    individual brand fonts.
  </GridListItem>
  <GridListItem title="Layout" titleElement="h3" linkText="Learn more about layout" linkTo="/foundations/layout" border icon="layout" mdxType="GridListItem">
    Read about spacing, grids, gutters and margins and how they vary across
    breakpoints.
  </GridListItem>
  <GridListItem title="Design tokens" titleElement="h3" linkText="Learn more about design tokens" linkTo="/foundations/design-tokens" border mdxType="GridListItem">
    Learn how to use design tokens to build and maintain consistent user interfaces.
  </GridListItem>
    </GridList>
    <Section mdxType="Section">
  <Button variant="filled" element="a" href="/foundations" mdxType="Button">
    See all foundations
  </Button>
    </Section>
    <hr></hr>
    <h2 {...{
      "id": "accessing-and-using-our-ui-design-kits",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#accessing-and-using-our-ui-design-kits",
        "aria-label": "accessing and using our ui design kits permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Accessing and using our UI design kits`}</h2>
    <p>{`We have UI design kits for use in Figma for Sainsbury’s, Argos, Nectar, Habitat and Tu.`}</p>
    <Section mdxType="Section">
  <GridList columns="2" mdxType="GridList">
    <GridListItem title="If you’re a Sainsbury’s colleague" titleElement="h3" filled mdxType="GridListItem">
      UI design libraries for each brand will be switched on within Figma. You
      can also{' '}
      <a href={links.figma.community}>
        duplicate our UI design kit from our community page.
      </a>
    </GridListItem>
    <GridListItem title="If you’re a third party or agency" titleElement="h3" filled mdxType="GridListItem">
      If you’re designing from outside Sainsbury’s organisation, you’ll need to{' '}
      <a href={links.figma.community}>
        duplicate the UI design kit from our community page.
      </a>
    </GridListItem>
  </GridList>
    </Section>
    <h3>{`Our UI design libaries contain:`}</h3>
    <GridList columns="3" mdxType="GridList">
  <GridListItem title="Colour Styles" titleElement="h4" removePadding="true" mdxType="GridListItem">
    Global monochrome and semantic palettes as well as brand colours.
  </GridListItem>
  <GridListItem title="Text Styles" titleElement="h4" removePadding="true" mdxType="GridListItem">
    Brand and global typefaces at different display sizes.
  </GridListItem>
  <GridListItem title="Grid Styles" titleElement="h4" removePadding="true" mdxType="GridListItem">
    Grids for mobile, tablet and desktop, with Android and iOS variants available.
  </GridListItem>
  <GridListItem title="Components" titleElement="h4" removePadding="true" mdxType="GridListItem">
    A core set of components for each brand within the assets tab.
  </GridListItem>
  <GridListItem title="Icons" titleElement="h4" removePadding="true" mdxType="GridListItem">
    Each brand’s icon suite within the assets tab.
  </GridListItem>
    </GridList>
    <Section mdxType="Section">
  <ButtonGroupWrapper mdxType="ButtonGroupWrapper">
    <ButtonGroupSecondary mdxType="ButtonGroupSecondary">
      <Button variant="filled" element="a" href={links.figma.community} className="ln-u-margin-right" mdxType="Button">
        See UI design kits on Figma
      </Button>
    </ButtonGroupSecondary>
  </ButtonGroupWrapper>
    </Section>
    <hr></hr>
    <h2 {...{
      "id": "guidelines-for-designers",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#guidelines-for-designers",
        "aria-label": "guidelines for designers permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Guidelines for designers`}</h2>
    <GridList columns="3" mdxType="GridList">
  <GridListItem title="How to use components" titleElement="h3" linkText="See component guidance" linkTo="/components" border mdxType="GridListItem">
    Find guidance on how to use Luna components.
  </GridListItem>
  <GridListItem title="Designing for accessibility" titleElement="h3" linkText="See accessibility guidance" linkTo="/accessibility" border mdxType="GridListItem">
    Read accessiblity guidance for digital.
  </GridListItem>
  <GridListItem title="Designing digital content" titleElement="h3" linkText="See content guidance" linkTo="/content" border mdxType="GridListItem">
    Tone of voice, style guides and best practices for content.
  </GridListItem>
    </GridList>
    <hr></hr>
    <GetInTouch mdxType="GetInTouch">
  Our design team are on hand to answers questions or help with your project.
    </GetInTouch>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      